import React from "react"
import WebsiteMeta from "../components/WebsiteMeta"
import FadeIn from "../components/FadeIn"
import ContactForm from "../components/ContactForm"
import styled from "styled-components"

export default function ContactPage() {
  return (
    <>
      <WebsiteMeta titleFull="Contact Us" />

      <FadeIn direction="up">
        <ContactSection id="contact">
          <FormWrapper>
            <ContactForm
              title="Reach Out to Our Team"
              subtitle="If you are interested in contributing to a future edition of the Priory Press or have any feedback, questions, or concerns, please let us know here."
              recipientname="Priory Press Team"
              recipientemail="publab"
            />
          </FormWrapper>
        </ContactSection>
      </FadeIn>
    </>
  )
}

const ContactSection = styled.section`
  h2 {
    margin-bottom: 0.5rem;
  }

  > p {
    line-height: 1.4;
  }
`

const FormWrapper = styled.div`
  margin-top: 2rem;
`
